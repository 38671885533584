@media only screen and (max-width: 568px) {
  .layout {
    background-color: #18ad65;
  }
  .layout-dekstop {
    display: none;
  }
  .layout-mobile {
    display: block;
  }
}
.layout-mobile {
  background-color: #18ad65;
}

@media only screen and (min-width: 568px) {
  .layout {
    background-color: #18ad65;
  }
  .layout-dekstop {
    display: block;
  }
  .layout-mobile {
    display: none;
  }
}
