@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Manrope' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 3px;
  height: 0px !important;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background: #18ad65;
}

::-webkit-scrollbar-track {
  background: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.profile {
  background-color: linear-gradient(0deg, #fff 50%, #102c42 50%);
}
.TableContainer {
  cursor: pointer;
}

.TableContainer::-webkit-scrollbar {
  width: 0px !important;
  height: 7px !important;
  background-color: #f2f0f0 !important;
}

.TableContainer::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.TableContainer::-webkit-scrollbar-track {
  background: none;
}

.amountTrx::-webkit-scrollbar {
  width: 0px !important;
  height: 7px !important;
  background-color: #f2f0f0 !important;
}

.amountTrx::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.amountTrx::-webkit-scrollbar-track {
  background: none;
}

.chartWrapper::-webkit-scrollbar {
  width: 0px !important;
  height: 7px !important;
  background-color: #f2f0f0 !important;
}

.chartWrapper::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.chartWrapper::-webkit-scrollbar-track {
  background: none;
}

ol,
ul {
  margin-left: 16px;
  padding-left: 16px;
}

ol li {
  list-style: decimal;
}

ul li {
  list-style: circle;
}

.pagination-new ul li {
  margin-left: 0;
  padding-left: 0;
  list-style: none !important;
}

.withoutDot ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none !important;
}
.withoutDot li {
  list-style: none !important;
}

/* CSS */
.spin {
  animation: spin 1s linear infinite; /* rotasi selama 1 detik */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-checkbox {
  filter: hue-rotate(250deg);
  cursor: pointer;
}
