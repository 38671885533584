.icon:hover {
  .img {
    filter: invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg)
      brightness(100%) contrast(81%);
  }
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #18ad65 !important;
  padding-right: 36px !important;
}

.active {
  background-color: #17251e;
  .img {
    filter: invert(68%) sepia(75%) saturate(6112%) hue-rotate(124deg)
      brightness(100%) contrast(81%);
  }
  color: #18ad65;
}

.subMenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .borderr {
    background-color: white;
  }
}
.subMenu:hover {
  .borderr {
    background-color: #18ad65;
  }
}

.sebMenuActive {
  .borderr {
    background-color: #18ad65;
  }
}

.MenuSide {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
}
